/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import "../geometry.js";
import e from "../request.js";
import r from "../TimeExtent.js";
import o from "../core/Error.js";
import i from "../core/Evented.js";
import { IdentifiableMixin as s } from "../core/Identifiable.js";
import a from "../core/Loadable.js";
import n from "../core/Logger.js";
import { isAbortError as l } from "../core/promiseUtils.js";
import { urlToObject as p } from "../core/urlUtils.js";
import { property as y } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/RandomLCG.js";
import { subclass as d } from "../core/accessorSupport/decorators/subclass.js";
import { fromPortalItem as m } from "./support/fromPortalItem.js";
import u from "../geometry/Extent.js";
import c from "../geometry/SpatialReference.js";
let h = 0,
  b = class extends i.EventedMixin(s(a)) {
    constructor() {
      super(...arguments), this.attributionDataUrl = null, this.fullExtent = new u(-180, -90, 180, 90, c.WGS84), this.id = Date.now().toString(16) + "-layer-" + h++, this.legendEnabled = !0, this.listMode = "show", this.opacity = 1, this.parent = null, this.persistenceEnabled = !1, this.popupEnabled = !0, this.attributionVisible = !0, this.spatialReference = c.WGS84, this.title = null, this.type = null, this.url = null, this.visibilityTimeExtent = null, this.visible = !0;
    }
    static async fromArcGISServerUrl(t) {
      const e = "string" == typeof t ? {
        url: t
      } : t;
      return (await import("./support/arcgisLayers.js")).fromUrl(e);
    }
    static fromPortalItem(t) {
      return m(t);
    }
    initialize() {
      this.when().catch(t => {
        l(t) || n.getLogger(this).error("#load()", `Failed to load layer (title: '${this.title ?? "no title"}', id: '${this.id ?? "no id"}')`, {
          error: t
        });
      });
    }
    destroy() {
      const t = this.parent;
      if (t) {
        const e = this;
        "layers" in t && t.layers.includes(e) ? t.layers.remove(e) : "tables" in t && t.tables.includes(e) ? t.tables.remove(e) : "baseLayers" in t && t.baseLayers.includes(e) ? t.baseLayers.remove(e) : "referenceLayers" in t && t.referenceLayers.includes(e) && t.referenceLayers.remove(e), this._set("parent", null);
      }
    }
    get hasAttributionData() {
      return null != this.attributionDataUrl;
    }
    get parsedUrl() {
      return p(this.url);
    }
    async fetchAttributionData() {
      const t = this.attributionDataUrl;
      if (this.hasAttributionData && t) {
        return (await e(t, {
          query: {
            f: "json"
          },
          responseType: "json"
        })).data;
      }
      throw new o("layer:no-attribution-data", "Layer does not have attribution data");
    }
  };
t([y({
  type: String
})], b.prototype, "attributionDataUrl", void 0), t([y({
  type: u
})], b.prototype, "fullExtent", void 0), t([y({
  readOnly: !0
})], b.prototype, "hasAttributionData", null), t([y({
  type: String,
  clonable: !1
})], b.prototype, "id", void 0), t([y({
  type: Boolean,
  nonNullable: !0
})], b.prototype, "legendEnabled", void 0), t([y({
  type: ["show", "hide", "hide-children"]
})], b.prototype, "listMode", void 0), t([y({
  type: Number,
  range: {
    min: 0,
    max: 1
  },
  nonNullable: !0
})], b.prototype, "opacity", void 0), t([y({
  clonable: !1
})], b.prototype, "parent", void 0), t([y({
  readOnly: !0
})], b.prototype, "parsedUrl", null), t([y({
  type: Boolean,
  readOnly: !0
})], b.prototype, "persistenceEnabled", void 0), t([y({
  type: Boolean
})], b.prototype, "popupEnabled", void 0), t([y({
  type: Boolean
})], b.prototype, "attributionVisible", void 0), t([y({
  type: c
})], b.prototype, "spatialReference", void 0), t([y({
  type: String
})], b.prototype, "title", void 0), t([y({
  readOnly: !0,
  json: {
    read: !1
  }
})], b.prototype, "type", void 0), t([y()], b.prototype, "url", void 0), t([y({
  type: r
})], b.prototype, "visibilityTimeExtent", void 0), t([y({
  type: Boolean,
  nonNullable: !0
})], b.prototype, "visible", void 0), b = t([d("esri.layers.Layer")], b);
const f = b;
export { f as default };